import React, { PureComponent } from "react";
import Footer from "../../../../containers/Footer";
import HeaderAfterLogin from "../../../../containers/HeaderAfterLogin";

export class ResponsibleGame extends PureComponent {
  render() {
    return (
      <div>
        <div>
          <HeaderAfterLogin />
        </div>
        <div>
          <main className="main mt-4">
            <div className="container">
              <h2 className="sectionTitle">Responsible Gaming</h2>
              <div className="responsible-game">
                <h3>Underage gambling</h3>
                <p>
                  We take strict steps to ensure that only people of legal age
                  participate and enter the Casino. Such checks may include
                  requesting the customer to submit a copy of a photographic
                  identification documents (such as a driver's licence, a
                  passport or a government-issued identity card), in order to
                  verify both age and identity.
                </p>
                <p>
                  The legal age for gaming online varies from country to country
                  and so we advise that you check the rules of your jurisdiction
                  before you play.
                </p>
                <p>
                  Any personal details we request from you are purely to verify
                  your identity – this is a legal requirement for both your own
                  and our protection. If you have any questions about our
                  identity checks, please do not hesitate to contact customer
                  service at any time.
                </p>
                <p>
                  Should you need to take a break from gambling, we provide a
                  self-exclusion facility which can be activated by contacting
                  Support. For more information please visit our Self-Exclusion
                  Policy that is accessible via the Casino’s website.
                </p>

                <h3>How you should view gambling</h3>
                <p>
                  Gambling should be viewed by you as a form of ENTERTAINMENT
                  only. You should never view gambling as a form of way to get
                  rich quickly and pay off your debts.
                </p>
                <p>
                  You should acknowledge that most of the people gambling
                  actually lose money.
                </p>
              </div>
            </div>
          </main>
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default ResponsibleGame;
